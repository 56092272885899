:root {
  --primary-color: #0487de;
  --secondary-color: #0f73b6;
  --color-dark: #000;
  --color-light: #fff;
  --accent-dark: #003847;
  --accent-light: #1fcade;
}

.mui-btn-dark {
  background-color: transparent !important;
  color: #111 !important;
  border: 1px solid #111 !important;
}

.mui-btn-dark:hover {
  border: 1px solid #111 !important;
  color: #111 !important;
  background-color: rgba(17, 17, 17, 0.05) !important;
}

p {
  /* margin-bottom: 1rem; */
  /* margin-top: 0; */
  margin: unset;
}

a {
  text-decoration: none;
}
