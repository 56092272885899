* {
  scroll-behavior: smooth;
}
/* @font-face {
  font-family: "Gilroy";
  src: local("Gilroy"),
    url(../../public/fonts/FontsFree-Net-Gilroy2.ttf) format("ttf");
} */
.text-size {
  font-size: 20px;
}

#copyright {
  text-align: center;
  margin: 0;
}

.countup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 0.5rem;
}

.countup p {
  font-size: 1.2rem;
}

.number-countup {
  font-size: 4rem;
  font-weight: bold;
}

.ub-text-card {
  border-radius: 0.25rem;
  box-shadow: 0 2px 10px rgba(172, 172, 172, 0.3);
  background: #fff;
  position: relative;
  width: 100%;
  padding: 1rem;
  margin-bottom: 1.2rem;
}

/* buttons css starts */
.button-blue-dark {
  /* background-color: #1976d2; */
  background-color: white;
  border: 1px solid #1976d2;
  padding: 0.2rem 1rem;
  border-radius: 50px;
  color: #1976d2 !important;
  transition: all 0.3s ease;
}

.button-blue-dark:hover {
  background-color: #1976d2;
  border: 1px solid #1976d2;
  padding: 0.2rem 1rem;
  border-radius: 50px;
  color: white !important;
  transform: scale(0.95);
}
/* buttons css ends */

.page-not-found {
  height: 80vh;
  display: flex;
  align-items: center;
}

/* .wrapper {
  display: flex;
  flex-flow: column;
  flex: 1;
} */

.footer-setter {
  flex: 1;
  background: linear-gradient(
    180deg,
    rgba(0, 41, 254, 0.9) 0%,
    rgba(34, 149, 255, 0.8) 100%
  );
  color: white;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.footer-item p {
  font-size: 0.9rem;
}

.footer-item h5 {
  color: white;
  /* margin-top: 10px; */
}

.footer-item a {
  color: white;
  font-size: 0.9rem;
}

.mainPageButtonThemeGradient {
  background: linear-gradient(
    113.91deg,
    #205295 1.2%,
    #205295 1.21%,
    #183efb 100%
  );
  filter: drop-shadow(0px 10px 50px rgba(255, 255, 255, 0.2));
  backdrop-filter: blur(100px);
}

.mainPageThemeGradient {
  background: linear-gradient(
    180deg,
    rgba(0, 41, 254, 0.9) 0%,
    rgba(34, 149, 255, 0.8) 100%
  );
  border: 1px solid #b4b4b4;
  border-radius: 40px;
}
.mainPageTextGradient {
  background: linear-gradient(180deg, #0029fe 0%, #0061d0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-emphasis-color: transparent;
}

.mainTextGradient {
  color: linear-gradient(180deg, #0029fe 0%, #0061d0 100%);
}

.swiper {
  width: 100%;
  margin-top: -5% !important ;
  height: 500px;
  /* height: 100%; */
}

.swiper-slide {
  text-align: center;
  align-items: initial;
  font-size: 18px;
  background: #fff;
  /* padding-top: -100px; */

  /* Center slide text vertically */
  /* display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center; */
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.custom-box-style {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #b4b4b4;

  box-shadow: 0px 4px 100px 10px rgba(104, 174, 255, 0.2);
  backdrop-filter: blur(100px);
  /* Note: backdrop-filter has minimal browser support */
  padding: 5%;
  border-radius: 20px;
}
.upper-box-circle {
  border-radius: 100%;
  /* New */

  background: linear-gradient(180deg, #0029fe 0%, #0061d0 100%);
  /* position: absolute; */
  width: 85px;
  height: 85px;
}
.custom-swiper-box-style {
  box-shadow: 0px 4px 50px 10px rgba(104, 174, 255, 0.1);
  padding-bottom: 10vh;
  height: 75% !important;
  /* padding-top: 15vh; */
  border-radius: 5%;

  /* margin-left: 4%; */
  /* margin-bottom: 10%; */
  margin-top: 5%;
}
.custom-images-text-box-style {
  box-sizing: border-box;

  background: linear-gradient(
    113.91deg,
    #ffffff 1.2%,
    rgba(255, 255, 255, 0.45) 1.21%,
    rgba(175, 175, 175, 0) 100%
  );
  border: 1px solid #b4b4b4;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  height: 100%;
}
.custom-images-text-box-style-background {
  box-sizing: border-box;

  background: linear-gradient(180deg, #0029fe 0%, #0061d0 100%);
  border: 1px solid #b4b4b4;
  box-shadow: 0px 4px 100px 10px rgba(104, 174, 255, 0.25);
  color: #ffffff;
  padding: 2rem;
}
.custom-box-image {
  width: 50%;
  height: auto;
}

.swiper-button-prev {
  background: linear-gradient(180deg, #0029fe 0%, #0061d0 100%);
  color: white !important;
  /* padding: 5px 25px 5px 20px;*/
  padding-right: 5px;

  width: 40px !important;
  height: 40px !important;

  font-weight: bolder;
  border-radius: 100%;
  left: 0px !important;
}
.swiper-button-next {
  background: linear-gradient(180deg, #0029fe 0%, #0061d0 100%);
  color: white !important;
  /* padding: 5px 25px 5px 20px;*/
  padding-left: 5px;

  width: 40px !important;
  height: 40px !important;

  font-weight: bolder;
  border-radius: 100%;
  right: 0px !important;
}
.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 20px !important;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0px !important;
  margin-bottom: 20px !important;
}

.swiper-pagination-bullet {
  background: linear-gradient(180deg, #0029fe 0%, #0061d0 100%);
  padding: 8px;
}

@media screen and (max-width: 900px) {
  .educate-icon-responsive {
    /* left: 300px;
    top: -50px; */
    display: none;
  }
}
@media screen and (max-width: 900px) {
  .calender-icon-responsive {
    /* left: 250px;
    top: -50px; */
    display: none;
  }
}
@media screen and (max-width: 900px) {
  .thumb-icon-responsive {
    display: none;
  }
}
@media screen and (max-width: 899px) {
  .curly-arrow-vector {
    height: 50px;
    /* width: 100px; */
    /* margin-top: 10px;
    margin-bottom: 10px; */
    /* transform: rotate(90deg); */
  }
}
@media screen and (max-width: 899px) {
  .arrow-vector {
    display: none;
  }
}
@media screen and (max-width: 899px) {
  .curly-arrow-vector {
    /* height: 50px; */
    display: none;
    /* margin-top: 10px;
    margin-bottom: 10px; */
    /* transform: rotate(90deg); */
  }
}
@media screen and (max-width: 420px) {
  .arrow-vector {
    height: 10px;
    margin: 60px;

    transform: rotate(90deg);
  }
}

@media screen and (max-width: 900px) {
  .step-one-res {
    margin-bottom: 40px !important;
  }
}

.landingPageBlueCircle {
  position: absolute;

  border-radius: 100px;
  background: linear-gradient(180deg, #0029fe 0%, #0061d0 100%);

  color: white;

  padding: 10px 20px 10px 20px;
  transform: translate(-15px, -15px);
  left: 0;
  top: 0;
}

@media screen and (max-width: 440px) {
  .landingPageBlueCircle {
    position: absolute;

    border-radius: 100px;
    background: linear-gradient(180deg, #0029fe 0%, #0061d0 100%);

    color: white;

    padding: 5px 15px 5px 15px;
    transform: translate(-15px, -15px);
    left: 0;
    top: 0;
  }
}
.testimonials-profile-circle {
  /* position: absolute; */

  border-radius: 100%;
  background: #d9d9d9;
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* margin-bottom: 8vh !important; */
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
  /* padding: 40px 40px 40px 40px; */
  width: 5em;
  height: 5em;
  /* content: " "; */
  /* transform: translate(0px, 0px); */
  /* left: 0;
  top: 0; */
}
.testimonial-avatar {
  border-radius: 100%;
}
.hover-underline-bold:hover {
  font-weight: bold;
  text-decoration: underline;
}
#candidate-header {
  color: #ffffff;
  background-image: url("../assets/candidateLandingPageImages/headerBG.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  /* height: 100vh; */
}
.candidate-header-heading {
  font-size: 8.625em;
  font-weight: 600;
}
@media screen and (max-width: 900px) {
  .candidate-header-heading {
    font-size: 48px;
  }
}
.candidate-bg {
  background-color: #000000;
  color: #ffffff;
  padding-bottom: 100px;
  /* font-family: "Gilroy"; */

  /* object-fit: cover; */
  /* width: 100%;
  height: 100vh; */
}
/* .headerVectorImageBg {
  background-image: url(../assets/candidateLandingPageImages/headerVectorBG.png);
  background-repeat: no-repeat;
} */
.headerVectorImage {
  /* border-radius: 5px; */
  /* height: 80%; */
  width: 50%;
  /* width: 312.53px;
height: 195.54px; */
  /* transform: rotate(1deg); */
}

.header-form {
  background-color: #ffffff;

  /* border-end-end-radius: 5px; */
}
.table-style {
  background: rgba(255, 255, 255, 0.2);
  border: 0.5px solid #ffffff;
  backdrop-filter: blur(112.5px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 10px;
}

.job-type-head {
  font-size: 1.6em;
  font-weight: 400;
  padding: 10px;
}
.job-type-details {
  margin: 10px;
  line-height: 25px;
  /* padding: 4px; */
  color: rgb(227, 221, 232);
}
.vertical-table-line {
  border-right: 6px solid green;
  height: 150px;
}
.whyChooseUsImages {
  width: 150px;
  height: 150px;
  margin: auto;
  padding: 10px;
}
.cmy {
  margin-top: 50px;
  margin-bottom: 50px;
}
.blank-box {
  width: 70%;
  height: 100%;

  color: #000000;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  align-content: center;
  background-color: #ffffff;
  box-shadow: 0px 4px 100px 30px rgba(255, 255, 255, 0.25);
  border-radius: 20px;
}
.blank-box-text {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  /* justify-content: space-bet; */
  /* display: flex; */
}
.inner-blank-box {
  aspect-ratio: 1/1;

  /* background: #d9d9d9; */
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin: 3%;
  height: 5em;
  width: 5em;

  border-radius: 10px;
  margin: 2%;
}

.mac-img {
  width: 70%;
  height: auto;

  /* object-fit: cover; */
  /* margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  vertical-align: middle; */
  /* position: absolute; */
}
@media only screen and (min-width: 900px) {
  .mac-img {
    margin-top: -50px;
  }
}
.mac-img-div {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
}
@media only screen and (max-width: 899px) {
  .blank-box-container {
    /* margin-left: 20% !important; */

    /* display: flex;
    justify-content: center;
    align-items: center;
    align-content: center; */

    width: 100%;
  }
}
@media only screen and (max-width: 899px) {
  .inner-blank-box {
    padding: 0;
    margin: 0;
  }
  .blank-box-text {
    margin: 0;
    padding: 0;
    text-align: center;
  }
  .blank-box {
    /* width: 100%; */
    margin-left: 10%;
  }
}
.tableBG {
  background-image: url("../assets/candidateLandingPageImages/tableBG.png");
  /* background-size: cover; */
  background-repeat: no-repeat;
  /* background-position: center; */
  background-size: cover;
  /* height: 30em; */
  /* aspect-ratio: 25/7; */
  border-radius: 20px;
  /* padding: 1000px; */
}
@media screen and (min-width: 900px) {
  .header-textfield {
    width: 335px !important;
  }
}
@media screen and (max-width: 900px) {
  .header-textfield-button {
    margin-top: 10px !important;
  }
}
@media screen and (max-width: 900px) {
  .footer-logo-responsive {
    width: 40%;
  }
}
@media screen and (max-width: 900px) {
  .candidate-footer-logo-responsive {
    width: 80%;
  }
}
@media screen and (min-width: 900px) {
  .candidate-footer-logo-responsive {
    width: 40%;
    /* opacity: 0.2; */
  }
}

/* .candidate-header-form-style {
  border-top-left-radius: 10px !important;
} */
.candidate-header-companies-logo {
  width: 100px;
  height: auto;
  padding: 10px;
  margin: 20px;
}
#candidate-footer {
  background-color: #150050;
  box-shadow: rgba(27, 48, 209, 0.76) 0px 22px 70px 4px;
  padding: 2%;

  /* margin: 10px; */
  /* box-shadow: #0029fe; */
  color: #ffffff;
}
@media screen and (max-width: 900px) {
  #candidate-footer {
    padding-top: 5%;
  }
}

.candidate-bottom-footer {
  background-color: #3f0071;
  color: #ffffff;
  padding: 10px;

  box-shadow: rgba(108, 60, 210, 0.76) 0px 22px 70px 4px;
}

.candidate-bottom-footer-a {
  font-size: 18px;
  color: #ffffff;
}
.candidate-bottom-footer-a:hover {
  font-size: 18px;
  color: #b4b4b4;
}
.candidate-footer-socials {
  color: #ffffff;
}
.candidate-footer-socials:hover {
  color: #b4b4b4;
}
.f-18 {
  font-size: 18px;
}
#section1 {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media screen and (max-width: 900px) {
  #section1 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
